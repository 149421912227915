
import Vue from 'vue'
import Dashboard from '@/components/Dashboard/index.vue'
import APIErrorMixin from '@/APIErrorMixin'

export default Vue.extend({
  name: 'App',
  components: { Dashboard },
  mixins: [APIErrorMixin],
  data () {
    return {
      loading: true,
      dashboardId: null,
      drawer: false,
      hiddenErrors: [
        'record_constraint_forbidden',
        'record_constraint_notify'
      ]
    }
  },
  methods: {
    showCookie () {
      const str = document.cookie
      const pattern = /is_cookies_agreed=true/
      const isAgreed = pattern.test(str)
      if (!isAgreed) {
        this.drawer = true
      }
    },
    agreedCookie () {
      // +1 день от текущей даты
      let oneDay = 86400e3
      let date = new Date(Date.now() + oneDay).toUTCString()
      document.cookie = `is_cookies_agreed = true; expires= ${date}`
      this.drawer = false
    }
  },
  async mounted () {
    if (this.$config.user_cookie) {
      this.showCookie()
    }
    const dashboardId = await this.$http.post(`${this.$config.api}/interfaceeditor/dashboards/portal`,
      {
        domain: window.location.host
      }, {
        hideNotification: true
      }).finally(() => {
      this.loading = false
    })

    if (Number.isInteger(dashboardId.data)) {
      this.dashboardId = dashboardId.data
    }
  }
})
