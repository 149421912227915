/// <reference path="./main.d.ts" />

import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/reset.css'
import 'element-ui/lib/theme-chalk/index.css'
import locale from '@/locale/ru.json'
import { Model } from 'vue-api-query'
import lang from 'element-ui/lib/locale/lang/ru-RU.js'
import ElementLocaleLib from 'element-ui/lib/locale'
import ElementLocaleSrc from 'element-ui/src/locale'
import 'vue-draggable-responsive/dist/vue-draggable-responsive.css'
import Portal from './Portal.vue'
import axios from 'axios'
import i18n from '@/i18n/i18n'
import 'moment/locale/ru'
import VueMask from 'v-mask'
import RoutingService from '@/services/InterfaceViewer/RoutingService'

ElementLocaleLib.use(lang)
ElementLocaleSrc.use(lang)
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(Element)
const moment = require('moment')
require('moment/locale/ru')
Vue.use(require('vue-moment'), {
  moment
})
Vue.use(VueMask, {
  placeholders: {
    Я: /[а-яА-Я]/
  }
})

Vue.mixin({
  methods: {
    generateGuid () {
      let d = new Date().getTime()
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now()
      }
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      })
    },
    toCamelCase (s: string): string {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    },
    toSnakeCase (s: string): string {
      return s
        .replace(/\B([A-Z])/gu, '_$1')
        .replace(/-/gu, '_')
        .toLowerCase()
    },
    getFilePath ({
      directory = undefined,
      guid,
      extension
    } : {directory?: string, guid: string, extension?: string}) {
      return `${directory ? directory + '/' : ''}${guid}${extension ? '.' + extension : ''}`
    },
    getFileName ({
      extension,
      name
    } : {extension?: string, name: string}) {
      return `${name}${extension ? '.' + extension : ''}`
    }
  }
})

Vue.prototype.$store = {
  getters: {
    'Authorization/roleId': 0,
    'Authorization/userId': 0,
    'Authorization/userAttributeData': (v) => {
      return null
    }
  }
}

Vue.prototype.$http = axios.create()
config.is_portal = true
Model.$http = Vue.prototype.$http
Vue.prototype.$daData = axios.create()
Vue.prototype.$daData.interceptors.request.use(function (config) {
  config.headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Token 03fab14d0555d6f7a9ea39754dbdc4771b4c3a8a'
  }
  return config
}, function (error) {
  return Promise.reject(error)
})
Vue.prototype.$config = config
Vue.prototype.$locale = locale
Vue.config.productionTip = false
RoutingService.location = window.location.pathname

// Yandex.Metrika counter
if (config.y_metrika_id) {
  const connectionMetrika = function (m, e, t, r, i, k?, a?) {
    m[i] = m[i] || function () {
      (m[i].a = m[i].a || []).push(arguments)
    }
    m[i].l = Number(new Date())
    for (var j = 0; j < document.scripts.length; j++) { if (document.scripts[j].src === r) { return } }
    k = e.createElement(t)
    a = e.getElementsByTagName(t)[0]
    k.async = 1
    k.src = r
    a.parentNode.insertBefore(k, a)
  }
  connectionMetrika(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')

  if (window['ym']) {
    window['ym'](config.y_metrika_id, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true
    })
  }
  console.log('Connect metrika', config.y_metrika_id)
}
// Yandex.Metrika counter
let VueApp: any = Vue
export default new VueApp({
  i18n,
  render: h => h(Portal),
  mounted () {}
}).$mount('#app')
